.faq-main-div {
    background-color: #e3f6f5;
}
.faqs-heading-maindiv {
    background: url(images/solutions_banner_bg.png);
    width: 100%;
    height: 150px;
}
.faqs-heading {
    margin-top: 110px;
    padding-top: 60px;
    text-align: center;
    font-weight: 700;
    color: #e4dfdf;
}
.faqs-questions {
    margin-left: 100px;
    margin-top: 20px;
    color: #3e3d3d;
    font-family: 'Nunito', sans-serif;
    font-weight: 900;
    font-size: 20px;
    cursor: pointer;
}
.faqs-answers {
    margin-left: 90px;
    color: #4f4c4c;
    background-color: rgb(243, 244, 244);
    width: 80%;
    font-size: 16px;    
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    padding-left: 30px;
    padding-right: 10px;
    overflow: hidden; 
    margin-bottom: 20PX;
    transition: all 0.8s ease;
    scroll-behavior: smooth;
    text-align: justify;
}
.faqs-answers-show {
    padding-top: 10px;
}
.faq-question-answer {
    background-color: rgb(239, 244, 244); 
    border: 2px solid rgb(207, 214, 216);
    box-shadow: 0 1px 1px 1px  rgb(203, 201, 201);
    margin-top: 10px;
    width: 70%;
}
.faq-question-answer-maindiv {
    /* position: relative; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
}
.sub-answer-title {
    color: rgb(28, 27, 27);
}
@media (max-width: 400px) {
    .faqs-heading {
        font-size: 18px;
    }
    .faqs-questions {
        margin-left: 10px; 
        font-size: 14px;
    }
    .faqs-answers {
        margin-left: 10px; 
        font-size: 14px;
        text-align: justify;
    }
    .faq-question-answer {
        width: 100%;
    }
}
@media (min-width: 401px) and (max-width: 600px) {
    .faqs-heading {
        font-size: 22px;
    }
    .faqs-questions {
        margin-left: 20px;
        font-size: 15px;
    }
    .faqs-answers {
        margin-left: 20px;
        font-size: 15px;
    }
    .faq-question-answer {
        width: 100%;
    }
}
@media (min-width: 601px) and (max-width: 960px) {
    .faqs-heading {
        font-size: 26px;
    }
    .faqs-questions {
        margin-left: 50px;
        font-size: 16px;
    }
    .faqs-answers {
        margin-left: 50px;
        font-size: 17px;    
    }
}