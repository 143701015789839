*:focus {
  outline: none;
}
body {
    margin:90px 0 0 0;
    padding:0;
    overflow-x: hidden;
}
.Container {
    display:flex;
    width:100%;
    font-family: 'Nunito', sans-serif;
}

.container-fluid {
    padding:0;
}

.row-reverse {
  flex-direction: row-reverse;
}

/* Nav bar Styles */

.navbar .navbar-collapse, .navbar {
    justify-content: flex-end;
    padding:5px 10px 10px 10px;
}

.navbar-expand-lg {
    margin-top:10px;
}

.navbar-nav {
  margin-left: 10px;
  width:100%;
  padding-left:10px;
  padding-bottom:15px;
}

.nav-item:hover {
  color: #330099 !important;
}

.nav-item {
    color: #9966ff !important;
    margin-right: 15px;
    font-size:1em;
}

.active-link {
    background-color: #66e766;
    color: #000000 !important;
    padding:5px 25px !important;
    border-radius: 25px;
    border: 1px solid transparent;
    cursor: pointer;
}

/* Home Banner */
.homebanner-bg {
    background:url(./images/home_banner_bg.png);
}

.solutions-bg {
  background:url(./images/solutions_banner_bg.png);
}

.img-responsive {
  width:100%;
}

/* Helper Class */
.padding-50 {
  padding:50px;
}

/* Heading Classes */
h1.heading {
  color:#ffffff;
  font-family: 'Nunito', sans-serif;
  font-size:1.5em;
  font-weight:300;
  line-height: 1.5em;
}

/* Button Styles */
.clear-btn:focus {
  outline : none;
}
.clear-btn {
  border:0;
  background-color: transparent;
  border:1.75px solid #66e766;
  padding:10px 15px;
  color: #66e766;
  width:200px;
  font-weight:600;
  border-radius:30px;
  cursor:pointer;
}
.clear-btn:hover {
  background-color: #66e766;
  color: #000000;
}
.active-link:hover {
  border:1px solid #006c00;
  color: #66e766;
  background-color: transparent;
}

.para {
  font-family: 'Nunito', sans-serif;
  font-size:1em;
  color:#5a5a5a !important;
}

.white-heading {
    color:#5a5a5a !important;
    font-weight: bold !important;
    font-family: 'Nunito', sans-serif;
    padding-left:0 !important;
}

.pink-btn {
    color:#9966ff;
    border: 1px solid #9966ff;
}

.pink-btn:hover {
    background-color: #9966ff;
    color:#ffffff;
}

.grey-bg {
    background-color: #eaeaea;
    padding-bottom:80px;
}

.brand-heading {
  padding:70px 15px 50px 15px;
  font-family: 'Nunito', sans-serif;
  font-size:2em;
  font-weight:600;
  color:#5a5a5a;
}

.brand-logos img {
  width:150px;
  margin-right:50px;
}

.comparision_section {
  background:url(./images/section_img.png);
  background-size: cover cover;
}

.comparision_section img {
  width: 100%;
  margin-top:-50px
}

.comaprision-table {
  text-align:center;
  position: absolute;
  width:100%;
  padding:20px;
  font-family: 'Nunito', sans-serif;
  color:#ffffff;
  font-size:2em;
  margin-top:40px;
}

.light-green-bg {
  padding:50px 0;
  background: linear-gradient(90deg, #dbfadb 50%, #ebffeb 50%);
}

/* Tabs Section */
.tabs {
  margin:50px 0 50px 0;
}

.tabs li{
  list-style: none;
  text-align: left;
  border: 1px solid #53e453;
  border-radius: 2rem;
  font-size:1.5rem;
  margin-bottom:25px;
  margin-left:-30px;
  padding:5px 0 10px 30px;
}

.tabs li:hover, .tabs li.Active {
  background-color:#ffffff;
  color:#5a5a5a;
  border: 1px solid #ffffff;
  cursor:pointer;
}

.tabs-container .pink-btn {
  width: 300px !important;
  font-size:1.3em;
  margin-bottom:15px;
  margin-left:10px;
  padding-left:30px;
  padding-right:30px;
}

h2.sub-heading {
  font-family: 'Nunito', sans-serif;
  color:#5a5a5a;
  font-size:1.5em;
  font-weight:400;
  line-height: 1.5em;
}

.why-utv-panel h2.sub-heading{
  padding:20px 0 0 0;
  font-weight:bold;
  width:80%;
  margin: 0 auto;
}

.why-utv-container {
  padding-bottom:100px;
}

.utv-card {
  -webkit-box-shadow: 1px 8px 12px -5px rgba(143,139,143,1);
  -moz-box-shadow: 1px 8px 12px -5px rgba(143,139,143,1);
  box-shadow: 1px 8px 12px -5px rgba(143,139,143,1);
  padding:0px 0 0px 0;
  margin-right:30px;
  border-bottom:10px solid transparent;
  cursor:pointer;
}

.utv-card:hover {
  -webkit-box-shadow: 10px 10px 25px -11px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 25px -11px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 25px -11px rgba(0,0,0,0.75);
  border-bottom:10px solid #9966ff;
}

.utv-card img {
  width:100%;
}

.panel p.para {
  font-size:1em !important;
  padding:20px 25px 20px 25px;
  color:#5a5a5a;
  min-height:113px;
  text-align: left;
}

.separator {
  height:2px;
  width: 90%;
  background-color: #d2d2d2;
  margin:0 auto;
}

.readmore-panel {
  width:100%;
  float:left;
  padding:10px;
}

.read-panel-left {
  float:left;
  width:50%;
  text-align: left;
  padding-left:15px;
  font-family: 'Nunito', sans-serif;
  font-weight:600;
}

.read-panel-right {
  float:right;
  width:50px;
}

.utv_footer {
  background: #3b0099;
  padding:50px 0 0 0;
}

.utv_footer .para {
  color:#ffffff !important;
}

.borderRight {
  border-right:1px solid #ffffff;
}

.borderBottom {
  border-bottom:1px solid #ffffff;
}

.footer-links {
  margin:20px 0 0 0;
  padding:0;
}

.footer-links li {
  list-style: none;
  display: inline;
  padding-right:20px;
  font-size:.9em;
}

.footer-links li a{
  color:#ffffff !important;
  font-family: 'Nunito', sans-serif;
  text-decoration: none;
}

.white-text {
  color:#ffffff !important;
}

.subscribe-textbox {
  background-color:transparent;
  border:1px solid #9966ff;
  border-radius: 20px;
  padding:5px 5px 5px 15px;
  color:#ffffff;
  font-family: 'Nunito', sans-serif;
}

.utv_footer .pink-btn {
  background-color:#9966ff;
  color:#ffffff;
  padding:5px 15px;
  border-radius: 20px;
  margin-left:10px;
  cursor:pointer;
}

.utv_footer .pink-btn:focus {
  outline: none !important;
}

.footer-social-icons {
  padding:20px 0 0 10px;
  width:100%;
}

.twitter-icon {
  padding-right:5px;
}

.utv_footer {
  padding:20px 0 20px 0 !important;
}

.desktop-only {
  display:block;
}

.mobile-only {
  display:none;
}

.header {
  position: fixed;
  width:100%;
  height:110px;
  background-color:#ffffff;
  padding-top:10px;
  z-index:99999;
  top:0;
  border-radius: 2px;
  box-shadow: 0px 1px 10px #999;
}

.close-icon {
  display: none !important;
}

@media only screen and (max-width: 960px) {
  .styled-select, .styled-select select {
	width:100% !important;
  }
  .navbar-nav a span.active-link {
    width:auto !important;
    padding:5px 10px !important;
    font-size:8pt;
  }
  .active-link {
    padding: 5px !important;
  }
  .navbar-nav {
    background-color:#6400FF;
  }
  .navbar-nav a{
    text-align:left;
    color:#ffffff !important;
  }
  .mobile-nav {
    position: absolute;
    right: 0;
  }
  .padding-50, .mobile-heading {
    padding:25px 2px 2px 2px !important;
  }
  .mobile-clear-btn {
    margin-left:0 !important;
    margin-top:5px !important;
    padding:5px !important;
    width:150px;
  }
  .mobile-left-padding {
    padding-left:40px !important;
  }
  .mobile-btn {
    margin-bottom:50px;
  }
  .brand-logos {
    text-align:revert;
    justify-content: center;
    padding-left:50px;
  }
  .brand-logos img {
    margin-bottom:30px;
  }
  .why-utv-panel-column {
    flex-direction: row;
    display: block;
  }
  .why-utv-panel {
    padding-bottom:20px;
  }
  .desktop-only {
    display:none;
  }
  
  .mobile-only {
    display:block;
  }
  .comaprision-table {
    position: relative;
  }
  .light-green-bg {
    background:#dbfadb;
  }
  .mobile-kompress-logo img, .features-image img{
    width:100% !important;
  }
  .mobile-kompress-description {
    padding:0 !important;
    margin-left:0 !important;
    font-size:.75em;
    width:80%;
  }
  .mobile-padding {
    padding-left:40px;
    padding-top:20px;
  }
  .panel {
    flex-direction: row;
    display: block;
    width:90%;
    margin:0 auto;
    padding-bottom:35px;
  }
  .why-utv-panel.utv-card {
    display:inline;
  }
  .panel p.para {
    min-height: auto !important;
  }
  .why-utv-container {
    padding-bottom:10px;
  }
  .container-fluid {
    padding:0;
    overflow-x:hidden;
  }
  .subscribe-textbox {
    width:100%;
  }
  .footer-links li {
    display: block;
    padding:10px;
  }
  .subscribe-btn {
    margin-top:20px;
  }
  .utv_footer {
    padding:20px 0 20px 0 !important;
  }
  .borderBottom {
    padding-top:15px;
  }
  .container {
    overflow-x:hidden !important;
  }
  .solution-four .why-utv-panel img {
    width:50% !important; 
  }
  .brand-heading {
    padding-bottom:25px !important;
  }

  .margin-top20 {
    margin-top:5px !important;
  }

  .advisor-card, .achievements, .team-card {
    flex-basis:inherit;
  }

  .navbar-expand-lg {
    position: fixed;
  }
  .navbar-toggler {
    margin-left:130px;
  }

  .energy-calculator .currency-selector {
    width:99%;
    margin:0 auto;
    margin-left:10px;
  }

  .hours-of-operation-textbox {
    width: 100% !important;
  }

  .header {
    height:90px;
  }

  .navbar {
    padding-top:15px;
  }

  .navbar-light .navbar-toggler-icon {
    background:url(./images/nav_bar.jpg) 100% 100%;
    background-size:cover;
    border:0;
    transform: rotateZ(-0deg);

  }

  .navbar-toggler {
    border:0;
    transition: all 0.4s ease;
    transform: rotateZ(-180deg);
  }
  .navbar-toggler:focus {
    outline:none; 
  }

  .navbar-toggler.collapsed, .navbar-toggler[aria-expanded="true"]{
    transform: rotateZ(-0deg);
  }

  .navbar-toggler.collapsed{
    transform: rotateZ(-180deg) !important;
  }

  .close-icon {
    position:absolute;
    right:30%;
    z-index:999;
    margin-top:-80px;
    font-size: 1.5em !important;
    display: block !important;
  }

  .sliding-panel {
    position:relative;
  }

  .mobile-top-10 {
    padding-top:20px;
  }

  .styled-select {
    width:100% !important;
    margin-left:10px !important;
    margin-bottom:15px;
    padding-left:5px !important;
    font-weight:bold !important;
  }



  .solutions .why-utv-panel {
    width:100% !important;
  }

  .solution-four .why-utv-panel {
    width:50% !important;
    float: left !important;
  }

  .mobile-top-20 {
    padding:20px 0 20px 0;
  }

@media only screen and (max-width: 960px) {
	.styled-select {
		width:70% !important;
		clear:both;
		margin:0 auto;
		margin-top:20px !important;
		margin-left:50px !important;
		
	}
}

























}


.green-para {
  color: #66e766 !important;
  font-weight:bold;
  font-size:1.5em;
  padding:20px;
}

.solution-banner-para {
  font-size:1em;
  color: #ffffff !important;
}

.pink-para {
  color:#6400FF !important;
  font-weight:600 !important;
  width:60%;
  padding:10px 0 10px 0;
  margin: 0 auto;;
}

.solutions .sub-heading {
  font-size:1em;
}

h3.sub-heading {
  font-weight:normal !important;
  text-align:center !important;
  font-size:1.2em;
  padding-bottom:20px;
  color:#5a5a5a;
  width:80%;
  margin:0 auto;
  line-height: 25pt;
}

.solution-sub-heading {
  padding-bottom:30px;
}

.solution-banner-para.third {
  width:85%;
  line-height:25pt;
  font-weight:bold;
  margin:0 auto;
  font-size:1.2em;
}

.solution-four {
  padding:20px 0px 20px 0;
}

.solution-four .pink-para {
  color:#ffffff !important;
  width:70%;
}

.solution-four .why-utv-panel img{
  width:60%;
}

.solutions-bg {
  padding-bottom:80px;
}

.advisor-naming {
  font-size:1.1em;
  color:#9966ff;
  font-weight:bold;
}

.advisor .para {
  font-size:.9em;
}

.margin-top20 {
  margin-top:25px;
}

.advisor-card {
  padding:10px;
  cursor:pointer;
  border-bottom:5px solid transparent;
}
.advisor-card:hover {
  background-color:#f5f5f5;
  padding:10px;
  border-bottom:5px solid #9966ff;
}

.white-box-container {
  background-color:#ffffff;
  width:95%;
  margin:0 auto;
  min-height:200px;
}

.white-box-container span {
  display:inline-block;
  margin:20px;
}

.white-box-container .sub-heading {
  padding:20px;
  font-size:1em !important;
  min-height:160px;
  font-weight: 600;
}

.team-card {
  margin-bottom:45px;
}

.team-heading {
  color: #6400ff;
  font-weight:bold;
  font-family: 'Nunito', sans-serif;
  font-size: 1.25em;
  padding:5px 0;
  margin: 0 0 20px 15px;
  border-top:1px solid #a266ff;
  border-bottom:1px solid #a266ff;
  opacity:0.7;
  position: relative;
}

.team-linkedin-item {
  position: absolute;
  right:10px;
}

.team-panel-bg {
  padding-bottom:30px;
}

label {
  font-family: 'Nunito', sans-serif;
}

.mandatory {
  color:#ff0000;
}

.schedulebtn {
  background-color:#9966ff;
  color:#ffffff;
}

.solutions-panel {
  padding-bottom:30px;
}

.read-panel-left a, .white-box-container a {
  color:#333333;
  text-decoration: none;
}

.advisor-naming a{
  color: #a266ff;
  text-decoration: none;
}

.energy-calculator .currency-selector {
  padding:5px;
  font-family: 'Nunito', sans-serif;
  margin-right:5px;
}

.energy-calculator .subscribe-textbox {
  color:#333333;
  
  font-size:1em;
  font-weight:bold;
  margin-top:10px;
  margin-left:10px;
  margin-bottom:20px;
}

.thick-font {
  font-weight:bold;
}

.hours-of-operation-textbox  {
  width:250px;
}

.privacy-container h2.sub-heading {
  color:#585858;
  font-weight:bold;
  padding:20px 10px 10px 0;
}

.privacy-container h3.sub-heading {
  text-align:left !important;
  font-weight:bold;
  margin: 0 !important;
}

.privacy-container h4.sub-heading {
  color:#585858;
  font-size:1.25em;
}

.privacy-container ul {
  margin:0;
  padding:0 0 20px 0;
}

.privacy-container ul li{
  padding:10px;
  color:#585858;
  margin-left:50px;
}

.copyrights {
  font-family: 'Nunito', sans-serif;
  color:#ffffff;
  padding-top:20px;
  font-size:.75em;
}

.success-subscribe {
  color:#ffffff;
  padding:10px 0 0 20px;
}

.styled-select {
  border: 1px solid #9966ff;
  box-sizing: border-box;
  border-radius: 20px;
  font-family: 'Nunito', sans-serif;
  color: #333333;
  font-size: 1em;
  font-weight: bold;
  margin-top:10px;
  overflow: hidden;
  position: relative;
  padding-left:15px;
  margin-left:50px;
}
.styled-select, .styled-select select { width: 130px; float:left;}
select:focus { outline: none; }
.styled-select select {
  height: 34px;
  padding: 5px 0 5px 5px;
  background: transparent;
  border: none;
  
  /*hide default down arrow in webkit */
  -webkit-appearance: none; 
}

@-moz-document url-prefix(){
  .styled-select select { width: 110%; }
}

.fa-sort-desc {
  position: absolute;
  top: 0;
  right: 12px;
  font-size: 24px;
}

 select::-ms-expand { display: none; } /* hide default down arrow in IE10*/


 .fa-sort-desc {
   margin-top:5px;
 }